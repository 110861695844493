@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    margin: 0; 
    font-family: Proxima Nova, system-ui, sans-serif;
    overflow-x: hidden;
  }

  h1 {
    margin: 0 0 20px 0;
    @apply text-3xl font-bold;
  }

  h2 {
    margin: 0 0 20px 0;
    @apply text-2xl font-semibold leading-normal;
  }

  h3 {
    margin: 0 0 20px 0;
    @apply text-sm md:text-xl font-semibold leading-normal;
  }

  p, label {
    margin: 0 0 20px 0;
    @apply text-gray-600 text-sm md:text-xl font-normal leading-loose;
  }

  a {
    @apply hover:text-secondary;
  }

  .container-layout {
    @apply container px-4 mx-auto;
  }

  .homepage-image {
    border-radius: 300px 0px 300px 300px;
  }

  .image-radius-right {
    border-radius: 0px 400px 400px 400px
  }

  .link {
    @apply underline text-primary hover:text-secondary visited:text-secondary
  }
  
  .button-link {
    @apply w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-secondary hover:text-white md:py-4 md:text-lg md:px-10;
  }

  input, textarea, select {
    @apply w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
  }

  .nav-items {
    @apply block px-4 py-2 text-black border-gray-100 no-underline hover:bg-gray-100 hover:text-primary rounded-lg text-xl outline-offset-0;
  }

  .rounded-number {
    @apply flex w-8 h-8 text-lg md:w-16 md:h-16 mx-auto items-center justify-center md:text-2xl font-bold rounded-full bg-blue-50 text-secondary;
  }
}
